import React, { useState, useContext, useEffect } from 'react'
import Upload from '../../../uicomponents/upload/upload'
import FutworkButton from '../../../uicomponents/button/Button'
import { ISContext } from './ISContext'
import FormInput from '../../../uicomponents/form-input/forminput'
import Papa from 'papaparse';
import ApiActions from '../../../actions/ApiActions'
import cloneDeep from "lodash/cloneDeep";
import { AlertIcon } from "../../../assets/images"
import ThreeDotLoader from '../../utilities/ThreeDotLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown, faQ } from '@fortawesome/free-solid-svg-icons'
import checkIsEmpty from '../../../helper/checkIsEmpty'

const Faq = ({ faqActiveLanguage }) => {
    const { teleprojectdata, allScripts, setAllScripts } = useContext(ISContext)
    const { title: projectTitle } = teleprojectdata

    const arrangeFaqs = () => {
        const result = allScripts && allScripts.map(script => {
            return {
                scriptId: script.id,
                scriptLanguage: script.scriptLanguage,
                faqs: script.faqs ? script.faqs : [],
                defaultScript: script.defaultScript
            }
        })
        return result
    }

    const [file, setFile] = useState('')
    const [apiResponse, setApiResponse] = useState({
        loading:false, status: '', msg: ''
    })
    const [faqsArray, setFaqsArray] = useState(arrangeFaqs())
    const [fileData, setFileData] = useState('')
    const [movingIndex, setMovingIndex] = useState(null);
    const [direction, setDirection] = useState(null);
    const [highlightedIndex, setHighlightedIndex] = useState(null);
    const [deleteEntireFaqAction, setDeleteEntireFaqsAction] = useState(false)
    const [isFormEdited, setIsFormEdited] = useState(false)

    useEffect(() => {
        setDeleteEntireFaqsAction(false)
        setApiResponse({loading:true, status: '', msg: ''})
        setTimeout(()=>{
            setApiResponse({
                status: '',
                msg: '',
                loading: false
            })
        },500)
    }, [faqActiveLanguage])

    const updateScript = (payload) => {
        const { scriptId, faqs } = payload
        const tmpScripts = cloneDeep(allScripts)
        const updateScript = tmpScripts.map(script => {
            if (script.id == scriptId) {
                script.faqs = faqs
            }
            return script
        })
        setAllScripts(updateScript)
    }

    const reorder = (array, sourceIndex, destinationIndex) => {
        const smallerIndex = Math.min(sourceIndex, destinationIndex);
        const largerIndex = Math.max(sourceIndex, destinationIndex);

        return [
            ...array.slice(0, smallerIndex),
            ...(sourceIndex < destinationIndex
                ? array.slice(smallerIndex + 1, largerIndex + 1)
                : []),
            array[sourceIndex],
            ...(sourceIndex > destinationIndex
                ? array.slice(smallerIndex, largerIndex)
                : []),
            ...array.slice(largerIndex + 1),
        ];
    }
    const moveUp = (index) => {
        if (index > 0) {
            setIsFormEdited(true)
            setMovingIndex(index);
            setDirection('up');
            setTimeout(() => {
                let [first] = filterFaqsArray()
                let { faqs } = first
                const rearrangedOrder = reorder(faqs, index, index - 1)
                const tempFaqsArray = faqsArray && faqsArray.map(faq => {
                    if (faq.scriptLanguage == faqActiveLanguage) {
                        return {
                            ...faq,
                            faqs: rearrangedOrder
                        }
                    }
                    else return faq
                })
                setFaqsArray(tempFaqsArray)
                setMovingIndex(null);
                setDirection(null);
                setHighlightedIndex(index - 1);
            }, 300); // match the transition duration in SCSS
        }
    }
    const moveDown = (index) => {
        let [first] = filterFaqsArray()
        let { faqs } = first
        if (index < faqs.length - 1) {
            setIsFormEdited(true)
            setMovingIndex(index);
            setDirection('down');
            setTimeout(() => {
                const rearrangedOrder = reorder(faqs, index, index + 1)
                const tempFaqsArray = faqsArray && faqsArray.map(faq => {
                    if (faq.scriptLanguage == faqActiveLanguage) {
                        return {
                            ...faq,
                            faqs: rearrangedOrder
                        }
                    }
                    else return faq
                })
                setFaqsArray(tempFaqsArray)
                setMovingIndex(null);
                setDirection(null);
                setHighlightedIndex(index + 1);
            }, 300); // match the transition duration in SCSS
        }
    }

    useEffect(() => {
        const handleClick = () => setHighlightedIndex(null);
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    const changeHandler = (e) => {
        // setFileLimit(false)
        const file = e.target.files[0]
        if (file && file.name && file.name.match(/\.(csv)$/)) {
            if (e.target.files.length > 0) {
                setFile(file)
                Papa.parse(file, {
                    header: true,
                    complete: function (results) {
                        const sanitizedResult = results.data.map(obj => {
                            if(!checkIsEmpty(obj)){
                                return obj
                            }
                        }).filter(Boolean)
                        setFileData(sanitizedResult)
                    }
                }
                )
            }

        } else {
            setFile('')
        }
    }

    const addQuestion = (index) => {
        setIsFormEdited(true)
        let [first] = filterFaqsArray()
        let { faqs } = first
        const newObj = {
            "question": "",
            "answer": ""
        }
        let tempFaqs = [...faqs]
        tempFaqs.splice(index + 1, 0, newObj)


        const tempFaqsArray = faqsArray && faqsArray.map(faq => {
            if (faq.scriptLanguage == faqActiveLanguage) {
                return {
                    ...faq,
                    faqs: tempFaqs
                }
            }
            else return faq
        })
        setFaqsArray(tempFaqsArray)
    }

    const deleteQuestion = (index) => {
        setIsFormEdited(true)
        const [first] = filterFaqsArray()
        const { faqs } = first
        let tempFaqs = [...faqs]
        const indexToRemoved = faqs.findIndex((i, idx) => idx == index)
        tempFaqs.splice(indexToRemoved, 1)

        const tempFaqsArray = faqsArray && faqsArray.map(faq => {
            if (faq.scriptLanguage == faqActiveLanguage) {
                return {
                    ...faq,
                    faqs: tempFaqs
                }
            }
            else return faq
        })
        setFaqsArray(tempFaqsArray)
    }

    const deleteEntireFaq = () => {
        setApiResponse({
            ...apiResponse,
            loading: true,
            msg: `Deleting the entire FAQ for ${faqActiveLanguage} language script`
        })
        const [first] = filterFaqsArray()
        const { faqs } = first
        const payload = {
            scriptId: first && first.scriptId,
            faqs: []
        }

        ApiActions.addorUpdateFaqs(payload).then(resp => {
            const tempFaqsArray = faqsArray && faqsArray.map(faq => {
                if (faq.scriptLanguage == faqActiveLanguage) {
                    return {
                        ...faq,
                        faqs: []
                    }
                }
                else return faq
            })
            setFaqsArray(tempFaqsArray)
            updateScript(payload)
            setApiResponse({
                loading: false,
                status: '',
                msg: ''
            })
        }).catch(err => {
            console.error(err.response)
        })
    }

    const saveFaqs = () => {
        setIsFormEdited(false)
        const [first] = filterFaqsArray()
        const { faqs } = first

        //check for validation
        const extractedErros = faqs && faqs.filter(faq => {
            if(checkIsEmpty(faq.question) || checkIsEmpty(faq.answer)){
                return faq
            }
        })
        if (extractedErros && extractedErros.length > 0) {
            setApiResponse({
                loading: false,
                msg: `${extractedErros.length} ${extractedErros.length > 1 ? `FAQ's` : `FAQ`} is incomplete`,
                status: 400
            })

            setTimeout(() => {
                setApiResponse({
                    loading: false,
                    msg: '',
                    status: ''
                })
            }, 1500)
            return true
        }
        const payload = {
            scriptId: first && first.scriptId,
            faqs: faqs
        }

        ApiActions.addorUpdateFaqs(payload).then(resp => {
            const tempFaqsArray = faqsArray && faqsArray.map(faq => {
                if (faq.scriptLanguage == faqActiveLanguage) {
                    return {
                        ...faq,
                        faqs: faqs
                    }
                }
                else return faq
            })
            setFaqsArray(tempFaqsArray)
            updateScript(payload)
            setApiResponse({
                loading: false,
                msg: 'The Faq has been updated',
                status: 200
            })
        }).catch(err => {
            console.error(err.response)
            const { response } = err
            const msg = response && response.data && response.data.error ? response.data.error : `Something went wrong`
            setApiResponse({
                loading: false,
                msg,
                status: 400
            })
        }).finally(() => {
            setTimeout(() => {
                setApiResponse({
                    loading: false,
                    msg: '',
                    status: ''
                })
            }, 1500)
        })
    }

    const getFormData = (key, value, idx) => {
        setIsFormEdited(true)
        let [first] = filterFaqsArray()
        let { faqs } = first
        let tempFaqs = [...faqs]
        let obj = tempFaqs[idx]
        obj = {
            ...tempFaqs[idx],
            [key]: value
        }
        tempFaqs[idx] = obj
        const tempFaqsArray = faqsArray && faqsArray.map(faq => {
            if (faq.scriptLanguage == faqActiveLanguage) {
                return {
                    ...faq,
                    faqs: tempFaqs
                }
            }
            else return faq
        })

        setFaqsArray(tempFaqsArray)

    }

    const renderForm = (feedback, i) => {
        // let [first] = filterFaqsArray()
        let { faqs } = first
        return faqs && faqs.map((faq, idx) => {
            return <div className={`container_wrapper ${movingIndex === idx ? (direction === 'up' ? 'moving-up' : 'moving-down') : ''} ${highlightedIndex === idx ? 'highlighted' : ''}`}>
                <div className='template_form'>
                    <div className='question-row'>
                        <div className='re-order-section'>
                            <FontAwesomeIcon icon={faAngleUp} className={idx == 0 ? 'disabled' : undefined} onClick={() => moveUp(idx)} />
                            <FontAwesomeIcon icon={faAngleDown} className={idx == faqs.length - 1 ? 'disabled' : undefined} onClick={() => moveDown(idx)} />
                        </div>
                    </div>

                    <FormInput
                        label={`Question ${idx + 1}`}
                        key={'question'}
                        onChange={(e) => {
                            getFormData('question', e.target.value, idx)
                        }}
                        value={faq.question}
                    />

                    <FormInput
                        type="textarea"
                        label="Answer"
                        key={'answer'}
                        onChange={(e) => {
                            getFormData('answer', e.target.value, idx)
                        }}
                        value={faq.answer}
                    />
                </div>
                <div className='footer_cta'>
                    <div className='add_question' onClick={() => addQuestion(idx)}>+Add Question</div>
                    <div className='delete_cta' onClick={() => deleteQuestion(idx)}>Delete</div>
                </div>
            </div>
        })
    };

    const filterFaqsArray = () => {
        return faqsArray && faqsArray.filter(faq => {
            if (faq.scriptLanguage == faqActiveLanguage)
                return faq
        })
    }

    const isFileValid = () => {
        return file ? false : true
    }

    const downloadFaq = () => {
        const getOriginalData = allScripts && allScripts.find(script => {
            if (script.scriptLanguage == faqActiveLanguage) {
                return script
            }
        })
        const { faqs } = getOriginalData

        const getFormattedTime = () => {
            var today = new Date();
            var y = today.getFullYear();
            // JavaScript months are 0-based.
            var m = today.getMonth() + 1;
            var d = today.getDate();
            var h = today.getHours();
            var ampm = h >= 12 ? 'pm' : 'am'
            var mi = today.getMinutes();
            var s = today.getSeconds();
            return `${d}-${m}-${y}-${h}-${mi}-${ampm}`;
        }

        const language = faqActiveLanguage ? faqActiveLanguage : ''
        const csvString = Papa.unparse(faqs);
        const blob = new Blob([csvString], { type: 'text/csv' });
        // generate a URL for the Blob.
        const blobUrl = URL.createObjectURL(blob);
        // create a link and set its href attribute to the generated URL.
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${projectTitle}-faq-${language}-${getFormattedTime()}.csv`; // Set the desired file name here
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // Release the object URL to prevent memory leaks
        URL.revokeObjectURL(blobUrl);


    }

    const submitFaq = () => {
        setApiResponse({
            ...apiResponse,
            loading: true,
            msg: 'The CSV file is getting uploaded, taking a moment to load the FAQ'
        })
        const [first] = filterFaqsArray()
        const { faqs } = first
        const payload = {
            scriptId: first && first.scriptId,
            faqs: fileData
        }

        ApiActions.addorUpdateFaqs(payload).then(resp => {
            const tempFaqsArray = faqsArray && faqsArray.map(faq => {
                if (faq.scriptLanguage == faqActiveLanguage) {
                    return {
                        ...faq,
                        faqs: fileData
                    }
                }
                else return faq
            })
            setFaqsArray(tempFaqsArray)
            updateScript(payload)
            setApiResponse({
                loading: false,
                status: 200,
                msg: ''
            })

        }).catch(err => {
            console.error(err.response)
        })
    }

    const [first] = filterFaqsArray()
    return (
        <div className='faq_wrapper'>
            {
                first && first.faqs && first.faqs.length > 0 ? <span>Total FAQ count: {first.faqs.length} </span> : null
            }
            {
                first && first.faqs && first.faqs.length > 0 ?
                    !apiResponse.loading ?
                        <div className='faq_management'>
                            {renderForm()}
                            {
                                apiResponse.msg ? <div className={`result_message ${apiResponse.status == 200 ? 'success' : 'error'}`}>{apiResponse.msg}</div>
                                    :
                                    <div className='button_row'>
                                        <FutworkButton buttonStyle="primary--solid non-rounded" buttonSize="sm"
                                            disabled={!isFormEdited ? true : false}
                                            onClick={() => saveFaqs()}>
                                            Submit
                                        </FutworkButton>
                                    </div>}
                            <div className='footer_row'>
                                {
                                    deleteEntireFaqAction ?
                                        <div className='action_trigger'>
                                            This action will delete the entire FAQ for {faqActiveLanguage} language. Are you sure you want to delete it ?
                                            <span className='accept' onClick={() => deleteEntireFaq()}>Yes</span>
                                            /
                                            <span className='decline' onClick={() => setDeleteEntireFaqsAction(false)}>No</span>
                                        </div>
                                        :
                                        <FutworkButton buttonStyle="danger--solid" buttonSize="sm" onClick={() => setDeleteEntireFaqsAction(true)}>
                                            Delete FAQ
                                        </FutworkButton>
                                }


                                <FutworkButton buttonStyle="primary--outline non-rounded" buttonSize="sm" onClick={() => downloadFaq()}>
                                    Download FAQ as CSV
                                </FutworkButton>
                            </div>
                        </div>
                        :
                        <div className='fallback-wrapper'>
                            <ThreeDotLoader />
                            {apiResponse && apiResponse.loading ?
                                <div className='fallback_text'>
                                    {apiResponse.msg}
                                </div>
                                : null
                            }
                        </div>
                    :
                    !first ?
                        <div className='fallback-wrapper'>
                            <img src={AlertIcon} />
                            <div className='fallback_text'>
                                You can add the FAQ only if the script exist in {faqActiveLanguage} language
                            </div>
                        </div>
                        :
                        <div className='upload_file'>
                            {
                                !apiResponse.loading ?
                                    <>
                                        <Upload placeholder='Select CSV file to upload' onChange={changeHandler} />
                                        <div className='button_row'>
                                            <FutworkButton buttonStyle="primary--solid non-rounded" buttonSize="sm" disabled={isFileValid()}
                                                onClick={() => submitFaq()}>
                                                Submit
                                            </FutworkButton>
                                        </div>
                                    </>
                                    :
                                    <div className='fallback-wrapper'>
                                        <ThreeDotLoader />
                                        {apiResponse && apiResponse.loading ?
                                            <div className='fallback_text'>
                                                {apiResponse.msg}
                                            </div>
                                            : null
                                        }
                                    </div>
                            }

                        </div>
            }
        </div>
    )
}

export default Faq